<template>
    <div class="right_main">
        <!-- 页面标题  按钮部分    上传组件部分-->
        <div>
            <div class="top_btn">
                <!-- 页面标题部分 -->
                <div class="page_name">
                    <div class="sig">
                        <i class="el-icon-arrow-left" @click="handle_goback"></i>
                    </div>
                    <div class="sig_name">导入</div>
                </div>
                <div class="btn_cl"></div>
            </div>
            <!-- 导入文件部分 -->
            <div class="from_main">
                <el-upload class="upload-demo" action="https://customer.ztxinde.com/servicer_import_show"
                    accept=".xlsx,.xls" name="file" :headers="myHeaders" :auto-upload="true" ref='upload'
                    :data="file_load" :before-upload="handleBefore" :on-success="handleSuccess" :file-list="fileList">
                    <el-button class="btn">导入</el-button>
                </el-upload>
                <!-- <div class="btn_main">
                    <el-button class="btn_rest" @click="ResterFn">取消</el-button>
                    <el-button class="btn_submit" @click="SubmitFn">上传</el-button>
                </div> -->
                <div v-if="tip_show" style="color:red;margin-top:70px;font-size:18px;font-weight:600">文件内容错误！请修改后重新上传！
                </div>
                <div class="btn_main">
                    <el-button class="btn_rest" @click="ResterFn">取消</el-button>
                    <el-button class="btn_submit" @click="SubmitLoad">提交已导入</el-button>
                </div>
            </div>
        </div>
        <!-- 页面标题  按钮部分    错误信息部分-->
        <!-- <div>
            <div class="top_btn">
                <div class="page_name">
                    <div class="sig"></div>
                    <div class="sig_name">错误信息</div>
                </div>
                <div class="btn_cl"></div>
            </div>
            <div class="from_main">
                <div class="sdf" v-if="succ_tip">暂无导入错误信息</div>
                <div class="ads" v-else>
                    <div class="table_list">
                        <div class="err_tip">{{area_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_area" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{name_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_name" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{phone_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_phone" :key="index">{{item.msg}}</div>
                    </div>
                    <div class="table_list">
                        <div class="err_tip">{{sex_tip}}</div>
                        <div class="err_data" v-for="(item,index) in err_sex" :key="index">{{item.msg}}</div>
                    </div>
                </div>
                <div class="btn_main">
                    <el-button class="btn_rest" @click="loadFn">重新导入</el-button>
                    <el-button class="btn_submit" @click="SubmitLoad">提交已导入</el-button>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { loadSalesApi } from '@/api'
export default {
    data () {
        return {
            // token
            myHeaders: {
                'token': ''
            },
            // 有没有错误信息
            has_err: '',
            // 上传成功的数据
            success_arr: [],
            // 有下级的id
            lower_id: '',
            // 登录用户id
            user_id: '',
            // 又没有下级
            lower: '',
            file_load: {
                auth_id: '',
                has_lower: '',
                lower_id: '',
            },
            fileList: [],
            // 提示语显示
            tip_show: false,
            // 上传显示
            upload_show: true,
            // 错误信息显示
            error_show: false,
            // 成功与错误信息隐藏显示
            succ_tip: true,
            // 地区错误  提示消息
            err_area: [],
            area_tip: '',
            // 名字错误 提示消息
            err_name: [],
            name_tip: '',
            // 手机号错误 提示消息
            err_phone: [],
            phone_tip: '',
            // 性别错误 提示消息
            err_sex: [],
            sex_tip: '',
        }
    },
    mounted () {
        this.lower_id = this.$route.query.lowerId;
        this.user_id = sessionStorage.getItem('id');
        this.lower = sessionStorage.getItem('lower');
    },
    methods: {
        // 上传文件前
        handleBefore (file) {
            this.myHeaders.token = sessionStorage.getItem('Token')
            this.file_load.auth_id = this.user_id;
            this.file_load.has_lower = this.lower;
            this.file_load.lower_id = this.lower_id;
        },
        // 上传文件成功
        handleSuccess (res, file, fileList) {
            // console.log(res)
            if (res.status.code == 200) {
                this.$message.success('文件上传成功');
                this.has_err = res.has_err;
                if (this.has_err == 0) {
                    this.tip_show = false;
                    this.success_arr = res.data;   //上传成功的数据
                } else if (this.has_err == 1) {
                    this.tip_show = true;
                };
                // console.log(fileList)
                this.fileList = [];
                this.fileList.push(file);
                // this.upload_show = false;
                // this.error_show = true;
                // if (this.has_err == 0) {
                //     this.succ_tip = true;
                // } else {
                //     this.succ_tip = false;
                //     this.err_area = res.errors.area.data;
                //     this.area_tip = '地区错误原因为:' + res.errors.area.msg;
                //     this.err_name = res.errors.name.data;
                //     this.name_tip = '名称错误原因为:' + res.errors.name.msg;
                //     this.err_phone = res.errors.phone.data;
                //     this.phone_tip = '手机号错误原因为:' + res.errors.phone.msg;
                //     this.err_sex = res.errors.sex.data;
                //     this.sex_tip = '性别错误原因为:' + res.errors.sex.msg;
                // }
            } else if (res.status.code == 201) {
                this.$message.error(res.status.msg);
                // this.upload_show = true;
                // this.error_show = false;
            } else if (res.status.code == 202) {
                this.$message.error('登录过期，请重新登录！');
                this.$router.push({ path: '/login' });
            }
        },
        // 点击取消按钮
        ResterFn () {
            this.$router.push({ path: '/detail', query: { id: this.lower_id } })
        },
        // 点击上传按钮
        SubmitFn () {
            this.$refs.upload.submit();
        },
        // 点击重新导入
        loadFn () {
            this.upload_show = true;
            this.error_show = false;
        },
        // 点击返回按钮返回
        handle_goback () {
            this.$router.push({ path: '/detail', query: { id: this.lower_id } })
        },
        // 点击提交已导入
        SubmitLoad () {
            loadSalesApi({
                data: this.success_arr,
                auth_id: this.user_id,
                has_lower: this.lower,
                lower_id: this.lower_id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.$message.success('提交成功');
                    this.$router.push({ path: '/detail', query: { id: this.lower_id } });
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' });
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #ffffff;
    position: relative;
    //  页面标题  按钮部分
    .top_btn {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        padding: 24px;
        border-bottom: 1px solid #e8e9f1;
        // 页面标题部分
        .page_name {
            display: flex;
            align-items: center;
            .sig {
                margin-right: 6px;
                i {
                    font-size: 24px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            .sig_name {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
            }
        }
        .btn_cl {
            width: 10px;
        }
    }
    // 输入框部分
    .from_main {
        padding: 24px;
        height: 540px;
        // 导入组件
        .upload-demo {
            width: 96%;
            height: 248px;
            border: 1px dashed #000000;
            position: relative;
            padding: 24px;
            .btn {
                width: 126px;
                height: 52px;
                border: none;
                background: #6a8be8;
                color: #ffffff;
                font-size: 18px;
                font-weight: 400;
                position: absolute;
                bottom: 15%;
                left: 45%;
            }
        }
        // 错误信息展示
        .sdf {
            text-align: center;
            padding: 20px;
            font-size: 18px;
            font-weight: 400;
            color: #20252b;
            text-align: left;
        }
        .ads {
            text-align: left;
            .table_list {
                text-align: left;
                padding: 0 16px;
                margin-bottom: 16px;
                .err_tip {
                    font-size: 20px;
                    font-weight: 600;
                    color: #20252b;
                    margin-bottom: 10px;
                }
                .err_data {
                    font-size: 18px;
                    font-weight: 400;
                    color: #20252b;
                }
            }
        }
        // 提交 重置按钮部分
        .btn_main {
            // margin-top: 100px;
            text-align: left;
            // padding: 0 20px 32px;
            position: absolute;
            left: 22px;
            bottom: 24px;
            .el-button {
                width: 160px;
                height: 40px;
                border-radius: 6px;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
            }
            .btn_submit {
                border: none;
                background: #6a8be8;
                margin-left: 8px;
                color: #ffffff;
            }
            .btn_rest {
                border: 1px solid #d9d9d9;
                color: #20252b;
                background: #ffffff;
            }
        }
    }
}
</style>